import Image from 'next/image';
import { useEffect, useState } from 'react';
import { CartProductItemProps } from './constants';
import axios from 'axios';
import { useUserStore } from '@/stores/useUserStore';
import { useCartsStore } from '@/stores/useCartsStore';
import { CURRENCY_OPTIONS } from '../Common/GlobalVars';
import { AttributeItem } from '@/lib/constants';
import { ProductVariantItemProps } from '../Product/constants';
import Link from 'next/link';
import { event } from 'nextjs-google-analytics';
import { getNewToken } from '@/lib/Definitions';


interface PlusCartProductItemProps extends CartProductItemProps {
  remove_cart: (cart_id: number) => void;
  show_add_to_cart: (cart_id: number) => void;
  attributes: AttributeItem[];
  calculate_order: () => void;
}

const CartProduct = (props: PlusCartProductItemProps) => {
  const { userInfo, getUser, updateUser } = useUserStore();
  const { carts, getCarts, updateCarts } = useCartsStore();
  const [isProcessing, setIsProcessing] = useState(false);
  const [displayAttr, setDisplayAttr] = useState<Array<string>>([]);
  const [variant, setVariant] = useState<ProductVariantItemProps | null>(null);
  const [userData, setUserData] = useState(getUser());
  const [cartsData, setCartsData] = useState(getCarts());

  const minus_qty = () => {
    if (props.qty > 1) {
      setIsProcessing(true);
      update_cart(props.id, props.product_variant_id, props.qty - 1)
        .then(() => {
          props.calculate_order();
        })
        .then(() => {
          setIsProcessing(false);
        });
    }
  }

  const plus_qty = () => {
    if (variant !== null && variant !== undefined && variant.qty > 0 && props.qty < variant.qty) {
      setIsProcessing(true);
      update_cart(props.id, props.product_variant_id, props.qty + 1)
        .then(() => {
          props.calculate_order();
        })
        .then(() => {
          setIsProcessing(false);
        });
    }
  }

  const update_cart = async (cart_id: number, variant_id: number, qty: number) => {
    const data = {
      variant_id: variant_id,
      qty: qty
    }

    try {
      const response = await axios.put(
        '/api/cart/' + cart_id,
        data,
        { headers: { Authorization: 'Bearer ' + userData.token } }
      )

      if (response.data.status === "ok") {
        event("update_cart", {
          value: variant?.sales_price,
          currency: process.env.NEXT_PUBLIC_CURRENCY_CODE,
          items: [
            {
              item_id: props.product_code,
              item_name: props.name,
              item_variant: variant?.product_code,
              brand: props.brand,
              price: variant?.special_price !== 0 ? variant?.special_price : props.special_price,
              quantity: qty,
              image: variant?.cart_image
            }
          ]
        });

        const idx = cartsData.data.findIndex(cart => cart.id === cart_id);

        if (idx > -1) {
          cartsData.data[idx].qty = qty;

          cartsData.update_date = Date.now();
          updateCarts(cartsData);
          setCartsData(cartsData);
        }
      }
    } catch (error: any) {
      if (error.response.data.error === "Invalid credentials") {
        const response: any = await getNewToken(userData.token, userData.refresh_token, userData.uuid);

        if (response !== "") {
          userData.token = response.token;
          userData.refresh_token = response.refresh_token;
          updateUser(userData);
          update_cart(cart_id, variant_id, qty);
        }
      }
    }
  }

  useEffect(() => {
    setUserData(getUser());
  }, [getUser, userInfo])

  useEffect(() => {
    setCartsData(getCarts());
  }, [getCarts, carts])

  useEffect(() => {
    const variant = props.product_variant_list.find((variant) => variant.variant_id === props.product_variant_id);

    if (variant) {
      setVariant(variant);
      const attr_list = [];

      for (var i = 0; i < props.attributes.length; i++) {
        const filtered = props.attributes[i].attribute_values.filter((attribute) => variant.attribute_value_list.some((value) => attribute.id === value)).sort((a, b) => (a.order === b.order) ? a.id - b.id : a.order - b.order);

        switch (props.attributes[i].slug) {
          case "color":
            if (filtered.length > 0) {
              attr_list.push(props.attributes[i].name + ": " + filtered[0].name);
            }
            break;

          case "size":
            if (filtered.length > 0) {
              attr_list.push(props.attributes[i].name + ": " + filtered[0].name);
            }
            break;

          case "stage":
            if (filtered.length > 0) {
              attr_list.push(props.attributes[i].name + ": " + filtered[0].name);
            }
            break;

          case "pattern":
            if (filtered.length > 0) {
              attr_list.push(props.attributes[i].name + ": " + filtered[0].name);
            }
            break;

          case "gender":
            if (filtered.length > 0) {
              attr_list.push(props.attributes[i].name + ": " + filtered[0].name);
            }
            break;

          case "flavour":
            if (filtered.length > 0) {
              attr_list.push(props.attributes[i].name + ": " + filtered[0].name);
            }
            break;
        }
      }

      setDisplayAttr(attr_list);
    }
  }, [props.attributes, props.product_variant_id, props.product_variant_list])

  const render_display_attributes = () => {
    return displayAttr.map((text, index) => {
      if (index > 0 && index <= displayAttr.length - 1) {
        return (
          <>
            <Image
              src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icon/circle-point.svg"}
              alt="circle point icon"
              width={4}
              height={4}
              quality={100}
              className="hidden mx-2 md:block"
            />
            <p>{text}</p>
          </>
        )
      } else {
        return (<p key={index}>{text}</p>)
      }
    })
  }

  return (
    <div className="flex mt-8 md:mt-4">
      <div className="min-w-[78px]">
        <Link href={"/product/" + props.slug}>
          <Image
            src={props.image}
            alt={props.image_alt}
            width={78}
            height={78}
            quality={100}
          />
        </Link>
      </div>
      <div className="ml-2 md:ml-10">
        <div className="grid gird-cols-1 md:grid-cols-3 gap-2 md:gap-4 text-sm text-[#2C3E2F] font-dmsans">
          <div className="font-normal md:col-span-2">
            <Link href={"/product/" + props.slug}>
              {props.name}
            </Link>
          </div>
          <div className="font-medium text-left md:text-right">
            <Link href={"/product/" + props.slug}>
              {process.env.NEXT_PUBLIC_CURRENCY}{props.special_price.toLocaleString("en-US", CURRENCY_OPTIONS)}
            </Link>
          </div>
          <div className="md:flex md:flex-wrap md:col-span-3">
            <Link href={"/product/" + props.slug}>
              {render_display_attributes()}
            </Link>
          </div>
          <div className="md:col-span-2">
            <div className="md:mt-2 border-[0.5px] border-[#C6C6C6] border-solid py-1 px-2 flex justify-between items-center w-[114px]">
              <Image
                src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icon/minus.svg"}
                alt="minus icon"
                width={14}
                height={14}
                quality={100}
                className="cursor-pointer"
                onClick={() => {
                  if (!isProcessing) {
                    minus_qty();
                  }
                }}
              />
              <input
                type="text"
                className="w-[50px] text-center text-black text-base h-4 bg-[#FCFCF8] focus:outline-none"
                value={props.qty}
                onChange={e => {
                  if (variant !== null && variant !== undefined && variant.qty > 0) {
                    let qty = 0

                    if (parseInt(e.currentTarget.value) < 1) {
                      qty = 1;
                    } else if (parseInt(e.currentTarget.value) <= variant.qty) {
                      qty = parseInt(e.currentTarget.value);
                    } else {
                      qty = variant.qty;
                    }

                    update_cart(props.id, props.product_variant_id, qty);
                  }
                }}
              />
              <Image
                src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icon/plus.svg"}
                alt="plus icon"
                width={14}
                height={14}
                quality={100}
                className="cursor-pointer"
                onClick={() => {
                  if (!isProcessing) {
                    plus_qty();
                  }
                }}
              />
            </div>
          </div>
          <div className="text-xs text-[#C6C6C6] underline flex justify-start md:justify-end items-center leading-loose md:leading-normal">
            <div
              className="cursor-pointer"
              onClick={() => props.show_add_to_cart(props.id)}
            >
              Edit
            </div>
            <div
              className="ml-2 cursor-pointer"
              onClick={() => props.remove_cart(props.id)}
            >
              Remove
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CartProduct
