import axios from "axios";
import { AttributeItem, CategoryItem } from "./constants";
import { CalculateResultParamItemProps, CalculatedResultItemProps } from "@/components/Cart/constants";
import { initResult } from "@/stores/initialState";


export const getCategories = async (): Promise<Array<CategoryItem>> => {
  try {  
    const response = await axios.get(
      process.env.NEXT_PUBLIC_API_URL! + process.env.NEXT_PUBLIC_API_CATEGORY!
    );

    return response.data;
  } catch (error) {
    return []
  }
}

export const getAttributes = async (): Promise<Array<AttributeItem>> => {
  try {
    const response = await axios.get(
      process.env.NEXT_PUBLIC_API_URL! + process.env.NEXT_PUBLIC_API_ATTRIBUTE!
    );

    return response.data;
  } catch (error) {
    return []
  }
}

export const getCalculatedResult = async (
  data: CalculateResultParamItemProps, token: string
): Promise<CalculatedResultItemProps> => {
  try {
    const response = await axios.post(
      process.env.NEXT_PUBLIC_API_URL! + process.env.NEXT_PUBLIC_API_ORDER! + "/calculate",
      data,
      { headers: { Authorization: token } }
    );

    return response.data;
  } catch (error) {
    return initResult;
  }
}

export const getNewToken = async (
  token: string, refresh_token: string, uid: string
): Promise<string> => {
  try {
    const response = await axios.post(
      '/api/refresh',
      { token: refresh_token, username: uid },
      { headers: { Authorization: token } }
    );

    return response.data;
  } catch (error) {
    return "";
  }
}
