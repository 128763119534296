import * as Dialog from '@radix-ui/react-dialog';


interface AlertDialogProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  message: string;
  action: () => void;
}

const AlertDialog = (props: AlertDialogProps) => {

  return (
    <Dialog.Root open={props.open} onOpenChange={props.setOpen}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 bg-[#282829] md:bg-[#595959] bg-opacity-50 md:bg-opacity-40 z-[60]" />
        <Dialog.Content className="fixed bottom-0 md:bottom-auto md:top-1/2 md:left-1/2 md:transform md:-translate-x-1/2 md:-translate-y-1/2 bg-[#FBF9F4] px-20 py-12 rounded-t-lg md:rounded-lg w-full md:w-fit md:max-w-1/2 md:min-w-[400px] mx-auto z-[65]">
          <Dialog.Description className="text-base text-center text-black font-dmsans">{props.message}</Dialog.Description>
          <div className="flex mt-[18px] justify-center space-x-2">
          <div
            className="bg-[#2C3E2F] px-8 py-2 text-base text-white font-dmsans cursor-pointer"
            onClick={() => props.action()}
          >Yes</div>
          <div
            className="bg-[#EFEFEF] px-8 py-2 text-base text-[#2C3E2F] font-dmsans cursor-pointer"
            onClick={() => props.setOpen(false)}
          >No</div>
        </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

export default AlertDialog;
