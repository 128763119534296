import { useEffect, useState } from "react";
import Image from 'next/image';
import { AttributeValueItem } from "@/lib/constants";
import { ProductAttributeItemProps } from "./constants";


const Attribute = (props: ProductAttributeItemProps) => {
  const { attribute_value_list, product_variant_list, attributes, variant, setVariant } = props;

  const [currentCode, setCurrentCode] = useState<string>("")
  const [colors, setColors] = useState<Array<AttributeValueItem>>([])
  const [sizes, setSizes] = useState<Array<AttributeValueItem>>([])
  const [stages, setStages] = useState<Array<AttributeValueItem>>([])
  const [patterns, setPatterns] = useState<Array<AttributeValueItem>>([])
  const [genders, setGenders] = useState<Array<AttributeValueItem>>([])
  const [flavours, setFlavours] = useState<Array<AttributeValueItem>>([])
  const [colorTitle, setColorTitle] = useState<string>("")
  const [sizeTitle, setSizeTitle] = useState<string>("")
  const [stageTitle, setStageTitle] = useState<string>("")
  const [patternTitle, setPatternTitle] = useState<string>("")
  const [genderTitle, setGenderTitle] = useState<string>("")
  const [flavourTitle, setFlavourTitle] = useState<string>("")
  const [selectColor, setSelectColor] = useState<number>(0)
  const [selectSize, setSelectSize] = useState<number>(0)
  const [selectStage, setSelectStage] = useState<number>(0)
  const [selectPattern, setSelectPattern] = useState<number>(0)
  const [selectGender, setSelectGender] = useState<number>(0)
  const [selectFlavour, setSelectFlavour] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [init, setInit] = useState<boolean>(false);

  // fetch selected variant
  useEffect(() => {
    const attr = [selectColor, selectSize, selectStage, selectPattern, selectGender, selectFlavour].filter((value) => value !== 0).sort((a, b) => a - b)
    const variants = product_variant_list.filter((variant) => variant.attribute_value_list.sort((a, b) => a - b).toString() == attr.toString())

    setVariant(variants[0]);
  }, [selectColor, selectSize, selectStage, selectPattern, selectGender, selectFlavour, product_variant_list, setVariant])

  useEffect(() => {
    const parser_attributes = async () => {
      try {
        for (var i = 0; i < attributes.length; i++) {
          const filtered = attributes[i].attribute_values.filter((attribute) => attribute_value_list.some((value) => attribute.id === value)).sort((a, b) => (a.order === b.order) ? a.id - b.id : a.order - b.order);

          let inVariant: any = null;
          if (variant !== null && variant !== undefined) {
            inVariant = filtered.find((attr) => variant.attribute_value_list.find((value) => value === attr.id));
          }

          switch (attributes[i].slug) {
            case "color":
              setColorTitle(attributes[i].name);
              setColors(filtered);

              if (inVariant) {
                setSelectColor(inVariant.id);
              }
              break;

            case "size":
              setSizeTitle(attributes[i].name);
              setSizes(filtered);

              if (inVariant) {
                setSelectSize(inVariant.id);
              }
              break;

            case "stage":
              setStageTitle(attributes[i].name);
              setStages(filtered);

              if (inVariant) {
                setSelectStage(inVariant.id);
              }
              break;

            case "pattern":
              setPatternTitle(attributes[i].name);
              setPatterns(filtered);

              if (inVariant) {
                setSelectPattern(inVariant.id);
              }
              break;

            case "gender":
              setGenderTitle(attributes[i].name);
              setGenders(filtered);

              if (inVariant) {
                setSelectGender(inVariant.id);
              }
              break;

            case "flavour":
              setFlavourTitle(attributes[i].name);
              setFlavours(filtered);

              if (inVariant) {
                setSelectFlavour(inVariant.id);
              }
              break;
          }
        }
      } finally {
        setIsLoading(false)
      }
    }

    if (init) {
      parser_attributes();
      setInit(false);
    }
  }, [attribute_value_list, attributes, init, variant]);

  useEffect(() => {
    const select_first_attribute = async () => {
      if (!isLoading) {
        if (colors.length > 0) {
          setSelectColor(colors[0].id);
        }

        if (sizes.length > 0) {
          setSelectSize(sizes[0].id);
        }

        if (stages.length > 0) {
          setSelectStage(stages[0].id);
        }

        if (patterns.length > 0) {
          setSelectPattern(patterns[0].id);
        }

        if (genders.length > 0) {
          setSelectGender(genders[0].id);
        }

        if (flavours.length > 0) {
          setSelectFlavour(flavours[0].id);
        }
      }
    }

    if (variant === null || variant === undefined) {
      select_first_attribute();
    }
  }, [colors, flavours, genders, init, isLoading, patterns, sizes, stages, variant]);

  useEffect(() => {
    if (init) {
      setIsLoading(false);
    }
  }, [init]);

  if (currentCode !== props.product_code) {
    setCurrentCode(props.product_code);
    setInit(true);
  }

  const handleClick = (type: string, value: number) => {
    switch (type) {
      case "color":
        setSelectColor(value);
        break;

      case "size":
        setSelectSize(value);
        break;

      case "stage":
        setSelectStage(value);
        break;

      case "pattern":
        setSelectPattern(value);
        break;

      case "gender":
        setSelectGender(value);
        break;

      case "flavor":
        setSelectFlavour(value);
        break;
    }
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {colors.length > 0 && (
        <div className={`${props.style === "normal" ? "mt-8" : "mt-4"}`}>
          <div className="text-sm md:text-xs text-[#2C3E2F] font-dmsans font-normal capitalize">{colorTitle}</div>
          <div className="flex flex-wrap">
            {colors.map((color, index) => (
              <Image
                key={index}
                src={color.image}
                alt={color.name}
                width={24}
                height={24}
                quality={100}
                className={`mt-2 mr-4 cursor-pointer rounded-full ${selectColor === color.id ? "border-[#2C3E2F] border-[2px] border-solid" : ""}`}
                onClick={() => handleClick("color", color.id)}
              />
            ))}
          </div>
        </div>
      )}
      {sizes.length > 0 && (
        <div className={`${props.style === "normal" ? "mt-8" : "mt-4"}`}>
          <div className="text-sm md:text-xs text-[#2C3E2F] font-dmsans font-normal capitalize">{sizeTitle}</div>
          <div className="flex flex-wrap">
            {sizes.map((size, index) => (
              <div
                key={index}
                className={`mt-2 mr-2 p-2 border-[0.5px] min-w-[34px] text-center border-solid ${selectSize === size.id ? "border-[#2C3E2F] border-[2px] border-solid" : "border-[#C6C6C6]"} text-[#2C3E2F] text-xs font-dmsans font-medium cursor-pointer`}
                onClick={() => handleClick("size", size.id)}
              >
                {size.name}
              </div>
            ))}
          </div>
        </div>
      )}
      {stages.length > 0 && (
        <div className={`${props.style === "normal" ? "mt-8" : "mt-4"}`}>
          <div className="text-sm md:text-xs text-[#2C3E2F] font-dmsans font-normal capitalize">{stageTitle}</div>
          <div className="flex flex-wrap">
            {stages.map((stage, index) => (
              <div
                key={index}
                className={`mt-2 mr-2 p-2 border-[0.5px] min-w-[34px] text-center border-solid ${selectStage === stage.id ? "border-[#2C3E2F] border-[2px] border-solid" : "border-[#C6C6C6]"} text-[#2C3E2F] text-xs font-dmsans font-medium cursor-pointer`}
                onClick={() => handleClick("stage", stage.id)}
              >
                {stage.name}
              </div>
            ))}
          </div>
        </div>
      )}
      {patterns.length > 0 && (
        <div className={`${props.style === "normal" ? "mt-8" : "mt-4"}`}>
          <div className="text-sm md:text-xs text-[#2C3E2F] font-dmsans font-normal capitalize">{patternTitle}</div>
          <div className="flex flex-wrap">
            {patterns.map((pattern, index) => (
              <div
                key={index}
                className={`mt-2 mr-2 p-2 border-[0.5px] min-w-[34px] text-center border-solid ${selectPattern === pattern.id ? "border-[#2C3E2F] border-[2px] border-solid" : "border-[#C6C6C6]"} text-[#2C3E2F] text-xs font-dmsans font-medium cursor-pointer`}
                onClick={() => handleClick("pattern", pattern.id)}
              >
                {pattern.name}
              </div>
            ))}
          </div>
        </div>
      )}
      {genders.length > 0 && (
        <div className={`${props.style === "normal" ? "mt-8" : "mt-4"}`}>
          <div className="text-sm md:text-xs text-[#2C3E2F] font-dmsans font-normal capitalize">{genderTitle}</div>
          <div className="flex flex-wrap">
            {genders.map((gender, index) => (
              <div
                key={index}
                className={`mt-2 mr-2 p-2 border-[0.5px] min-w-[34px] text-center border-solid ${selectGender === gender.id ? "border-[#2C3E2F] border-[2px] border-solid" : "border-[#C6C6C6]"} text-[#2C3E2F] text-xs font-dmsans font-medium cursor-pointer`}
                onClick={() => handleClick("gender", gender.id)}
              >
                {gender.name}
              </div>
            ))}
          </div>
        </div>
      )}
      {flavours.length > 0 && (
        <div className={`${props.style === "normal" ? "mt-8" : "mt-4"}`}>
          <div className="text-sm md:text-xs text-[#2C3E2F] font-dmsans font-normal capitalize">{flavourTitle}</div>
          <div className="flex flex-wrap">
            {flavours.map((flavour, index) => (
              <div
                key={index}
                className={`mt-2 mr-2 p-2 border-[0.5px] min-w-[34px] text-center border-solid ${selectFlavour === flavour.id ? "border-[#2C3E2F] border-[2px] border-solid" : "border-[#C6C6C6]"} text-[#2C3E2F] text-xs font-dmsans font-medium cursor-pointer`}
                onClick={() => handleClick("flavour", flavour.id)}
              >
                {flavour.name}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  )
}

export default Attribute
